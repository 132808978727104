<template>
    <div class="articles">
        <banner :src="article.banner"></banner>
        <div class="site-content animate">
            <main class="site-main">
                <article class="hentry">
                    <!-- 文章头部 -->
                    <header class="entry-header">
                        <!-- 标题输出 -->
                        <h1 class="entry-title">{{article.title || '--'}}</h1>
                        <hr>
                        <div class="breadcrumbs">
                            <div id="crumbs">文章发布于：{{article.createTime || 0 | formatTime('{y}-{m}-{d} {h}:{i}')}}</div>
                        </div>
                    </header>
                    <!-- 正文输出 -->
                    <div class="entry-content" v-highlight ref="entryContent" v-html="article.content"></div>
                    <!-- 文章底部 -->
                    <section-title>
                        <footer class="post-footer">
                            <!-- 阅读次数 -->
                            <div class="post-like">
                                <i class="iconfont iconeyes"></i>
                                <span class="count">{{article.viewsCount || 0}}</span>
                            </div>
                            <div class="donate" @click="showDonate=!showDonate">
                                <span>赏</span>
                                <ul class="donate_inner" :class="{'show':showDonate}">
                                    <li class="wedonate"><img src="http://cdn.fengziy.cn/gblog/wexin_pay.png"><p>微信</p></li>
                                    <li class="alidonate"><img src="http://cdn.fengziy.cn/gblog/ali_pay.jpg"><p>支付宝</p></li>
                                </ul>
                            </div>
                            <!-- 文章标签 -->
                            <div class="post-tags">
                                <i class="iconfont iconcategory"></i>
                                <router-link :to="`/category/${article.categoryTitle}`">{{article.categoryTitle || 'web'}}</router-link>
                            </div>
                        </footer>
                    </section-title>

                    <!--声明-->
                    <div class="open-message">
                        <p>声明：Gblog博客|版权所有，违者必究|如未注明，均为原创|本网站采用<a href="https://creativecommons.org/licenses/by-nc-sa/3.0/" target="_blank">BY-NC-SA</a>协议进行授权</p>
                        <p>转载：转载请注明原文链接 - <a :href="postPath" target="_blank">{{article.title || '--'}}</a></p>
                    </div>
                    <!--评论-->
                    <div id="comment-module">
                        <div style="padding: 30px 0;">
                            <section-title>
                                <div class="comment-hr">
                                    <div class="comment-title"><span>Comments</span>|<span> {{article.commentsCount || 0}} 条评论</span></div>
                                    <div v-if="!enableComment">暂时未开放，尽请期待...</div>
                                </div>
                            </section-title>
                        </div>
                        <comment v-for="item in comments" :key="item.comment.id" :comment="item.comment">
                            <template v-if="item.reply.length">
                                <comment v-for="reply in item.reply" :key="reply.id" :comment="reply"></comment>
                            </template>
                        </comment>
                    </div>
                </article>
            </main>
            <section class="right-side">
                <!-- 文章目录 -->
                <div id="article-menus">
                    <menu-tree class="mb" :menus="menus" :menusList="menusList" child-label="child"></menu-tree>
                    <hr style="margin: 20px 0;"/>
                    <div style="text-align: center;">
                        <img style="width: 200px;" :src="article.fzyWxqrcode" alt="">
                    </div>
                </div>
            </section>
        </div>

    </div>
</template>

<script>
    import Banner from '@/components/banner'
    import sectionTitle from '@/components/section-title'
    import comment from '@/components/comment'
    import {fetchComment, fetchPost} from '../api'
    import menuTree from '@/components/menu-tree'
    import {mapGetters} from 'vuex'
    export default {
        name: 'articles',
        data() {
            return {
                postId: '',
                postPath: '',
                article: {},
                showDonate: false,
                comments: [],
                menus: [],
                menusList: []
            }
        },
        computed: {
            enableComment() {
              return this.websiteInfo.enable_comments && this.websiteInfo.enable_comments === 'true'
            },
            ...mapGetters([
                'websiteInfo'
            ])
        },
        components: {
            Banner,
            sectionTitle,
            comment,
            menuTree
        },
        watch: {
            $router: {
                immediate: true,
                handler() {
                    this.postId = this.$route.params.id
                    this.postPath = window.location.href
                    this.getArticle()
                }
            },
            article: {
                immediate: true,
                handler(v) {
                    if (v.content) {
                        this.$nextTick(() => {
                            this.createMenus()
                        })
                    }
                }
            }
        },
        methods: {
            getComment() {
                fetchComment(this.postId).then(res => {
                    this.comments = res.data || []
                }).catch(err => {
                    console.log(err)
                })
            },
            getArticle() {
                fetchPost(this.postId).then(res => {
                    this.article = res.data || {}
                })
            },
            fetchH(arr, left, right) {
                if (right) {
                    return arr.filter(item => item.offsetTop > left && item.offsetTop < right)
                } else {
                    return arr.filter(item => item.offsetTop > left)
                }
            },
            // 生成目录
            createMenus() {
                let entryContent = this.$refs.entryContent;
                let arr = []
                let list = []
                for (let i = 6; i > 0; i--) {
                    let temp = []
                    let e = entryContent.getElementsByTagName(`h${i}`) || []
                    for (let j = 0; j < e.length; j++) {
                        e[j].setAttribute('id',e[j].offsetTop);
                        list.push({
                            h: i,
                            title: e[j].innerText,
                            id: e[j].offsetTop,
                            offsetTop: e[j].offsetTop
                        })
                        let child = this.fetchH(arr, e[j].offsetTop, (j + 1 === e.length) ? undefined : e[j + 1].offsetTop)
                        temp.push({
                            h: i,
                            title: e[j].innerText,
                            id: e[j].offsetTop,
                            offsetTop: e[j].offsetTop,
                            child
                        })
                    }
                    if (temp.length) {
                        arr = temp
                    }
                }
                this.menus = arr
                this.menusList = list.sort((a, b) => a.offsetTop - b.offsetTop)
            }
        }
    }
</script>
<style scoped lang="less">
    /*.article-content{*/
    /*    display: flex;*/
    /*    justify-content: center;*/
    /*}*/
    .site-content {
        display: flex;
        position: relative;
        .site-main {
            max-width: 800px;
            padding: 80px 0 0 0;
        }
    }
    article.hentry {
        .entry-header {
            .entry-title {
                font-size: 23px;
                font-weight: 600;
                color: #737373;
                margin: 0.67em 0;

                &:before {
                    content: "#";
                    margin-right: 6px;
                    color: #d82e16;
                    font-size: 20px;
                    font-weight: 600;
                }
            }

            hr {
                height: 1px;
                border: 0;
                background: #EFEFEF;
                margin: 15px 0;
            }

            .breadcrumbs {
                font-size: 14px;
                color: #D2D2D2;
                text-decoration: none;
                margin-bottom: 30px;
            }
        }

        .entry-content {
        }

        footer.post-footer {
            width: 100%;
            padding: 20px 10px;
            margin-top: 30px;
            height: 65px;
            position: relative;

            i {
                font-size: 18px;
                margin-right: 5px;
            }

            .post-like {
                float: right;
                margin: 7px 0 0 20px;
            }

            .post-share {
                float: right;
                list-style: none;
                margin-right: 20px;
            }

            .donate {
                float: left;
                line-height: 36px;
                border-radius: 100%;
                -webkit-border-radius: 100%;
                -moz-border-radius: 100%;
                border: 1px solid #2B2B2B;

                &:hover {
                    border: 1px solid goldenrod;

                    span {
                        color: goldenrod;
                    }
                }

                span {
                    color: #2B2B2B;
                    padding: 10px;
                    position: relative;
                    cursor: pointer;
                }

                .donate_inner {
                    display: none;
                    margin: 0;
                    list-style: none;
                    position: absolute;
                    left: 80px;
                    top: -40px;
                    background: #FFF;
                    padding: 10px;
                    border: 1px solid #ddd;
                    box-shadow: 0 2px 6px rgba(0, 0, 0, .08);
                    border-radius: 3px;

                    &.show {
                        display: block;
                    }

                    li {
                        float: left;
                    }

                    img {
                        width: 100px;
                    }

                    p {
                        text-align: center;
                        font-size: 15px;
                        color: #D2D2D2;
                        line-height: 1rem;
                    }
                }

                .donate_inner:after, .donate_inner:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 45%;
                    margin-left: -8px;
                    border-top: 8px solid transparent;
                    border-bottom: 8px solid transparent;
                    border-right: 8px solid #fff;
                }

                .donate_inner:before {
                    left: -1px;
                    border-right: 8px solid #ddd;
                }

            }

            .post-tags {
                margin: 7px 0 0 20px;
                float: left;
                text-transform: uppercase;

                a:hover {
                    color: #ff6d6d;
                }
            }
        }

        .open-message {
            margin: 50px 0;
            position: relative;
            background: #2B2B2B;
            padding: 10px 30px;
            border-radius: 3px;
            font-size: 14px;
            color: #fff;

            &:after {
                content: "";
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid #2B2B2B;
                position: absolute;
                top: -8px;
                left: 48%;
            }

            p {
                margin: 10px 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            a {
                color: #A0DAD0;
                padding: 0 5px;
            }
        }

        #comment-module {
            padding-top: 30px;
            margin-bottom: 30px;
        }

        .comment-hr {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            .comment-title {
                span:nth-of-type(1) {
                    font-size: 18px;
                    font-weight: bold;
                    margin-right: 20px;
                }

                span:nth-of-type(2) {
                    font-size: 16px;
                    margin-left: 20px;
                }
            }
        }
    }
    .right-side{
        margin-left: 100px;
        #article-menus{
            position: sticky;
            top: 0;
            box-shadow: 0 2px 6px rgba(0, 0, 0, .1);
            border-radius: 3px;
            padding: 15px;
            width: 300px;
            transform: translateY(150px);
            font-size: 14px;
            .mb{
                max-height: 300px;
                overflow-y: auto;
            }
        }
    }
    @media (max-width: 1600px) {
        .right-side {
            margin-left: 30px;
        }
    }
    @media (max-width: 1490px) {
        .right-side {
            display: none;
        }
    }
    @media (max-width: 800px) {
        .site-content{
            display: block;
        }
        .right-side {
            display: none;
        }
    }
</style>
