<template>
    <ul class="menus">
        <li v-for="item in menus">
            <span class="menu-item" :class="{'active': item.id === active }" @click.stop="tapMoot(item.id, item.offsetTop)">●{{item.title}}</span>
            <template v-if="item[childLabel] && item[childLabel].length">
                <menu-tree class="child" :menus="item[childLabel]" :menusList="menusList" :child-label="childLabel"></menu-tree>
            </template>
        </li>
    </ul>
</template>

<script>
    export default {
        name: "menuTree",
        props: {
            menus: {
                type: [Object,Array],
                required: true
            },
            menusList: {
                type: [Object,Array],
                required: true
            },
            childLabel: {
                type: String,
                default: 'child'
            }
        },
        computed: {
            active(){
                for (let i = 0; i < this.menusList.length; i++) {
                    if (i === this.menusList.length - 1 && this.scrollTop > this.menusList[i].offsetTop) {
                        return this.menusList[i].id
                    }
                    if (this.scrollTop > this.menusList[i].offsetTop && this.scrollTop < this.menusList[i+1].offsetTop) {
                        return this.menusList[i].id
                    }
                }
            }
        },
        data() {
            return{
                current: '',
                scrollTop: 0
            }
        },
        mounted(){
            window.addEventListener('scroll', this.watchScroll)
        },
        beforeDestroy () {
            window.removeEventListener("scroll", this.watchScroll)
        },
        methods: {
            watchScroll() {
                this.scrollTop = (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) - 50
            },
            tapMoot(id, height) {
                document.documentElement.scrollTop = height + 70
                this.active = id
            }
        }
    }
</script>

<style scoped lang="less">
    .menus{
        line-height: 1.7em;
    }
    .child{
        padding-left: 15px;
    }
    .menu-item:hover{
        opacity: 0.7;
        cursor: pointer;
    }
    .menu-item.active{
        font-weight: bold;
        color: #A0DAD0;
    }
</style>
